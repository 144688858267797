/**
 * Customer Management - Vermo Management
 * This service is used to manage details about customers.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: michal.janocha@vermohub.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BillingPackageOutput { 
    id?: string;
    billingType?: string;
    packageName?: string;
    packageTitle?: string;
    effectiveStartDate?: string;
    effectiveEndDate?: string;
    value?: number;
    usedValue?: number;
    cost?: number;
    costCurrency?: string;
    paymentStatus?: BillingPackageOutput.PaymentStatusEnum;
    status?: BillingPackageOutput.StatusEnum;
}
export namespace BillingPackageOutput {
    export type PaymentStatusEnum = 'PAID' | 'NOT_PAID';
    export const PaymentStatusEnum = {
        PAID: 'PAID' as PaymentStatusEnum,
        NOTPAID: 'NOT_PAID' as PaymentStatusEnum
    };
    export type StatusEnum = 'ACTIVE' | 'INACTIVE' | 'DRAINED';
    export const StatusEnum = {
        ACTIVE: 'ACTIVE' as StatusEnum,
        INACTIVE: 'INACTIVE' as StatusEnum,
        DRAINED: 'DRAINED' as StatusEnum
    };
}


